import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import SignsImg from "../../components/images/advertising/signs"

export default function Signs() {
    return (
        <>
            <Layout>
                <SEO title="Signs" />
                <Display
                    title="Signs"
                    description="Printed signs of all shapes and sizes are an essential part of your marketing and advertising. Reach & inform your customers with high quality, yet affordable signs that grab attention and get noticed."
                    prev="/advertising/flags"
                    next="/advertising/banners"
                    image={<SignsImg />}
                />
            </Layout>                
        </>
    )
}
